import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.trim.js";
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['djadmin'];
  return valid_map.indexOf(str.trim()) >= 0;
}
// 电话号码验证
export function isvalidPhone(str) {
  var reg = /^((\d{8})|(1[23456789]\d{9}))$/;
  return reg.test(str);
}
// 手机号验证
export function isMoblePhone(str) {
  var reg = /^(1[23456789]\d{9})$/;
  return reg.test(str);
}