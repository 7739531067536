//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from 'echarts';
import AMap from 'AMap'; // 引入高德地图
export default {
  data: function data() {
    return {
      listData: [{
        startLocation: '石家庄市裕华区建材城西路100楼',
        endLocation: '石家庄市新华区门神小区',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市桥西区建材城西路100楼',
        endLocation: '石家庄市开发区湘江大道123号',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市长安区建材城西路100楼',
        endLocation: '石家庄市鹿泉区海山大街200号',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市裕华区建材城西路100楼',
        endLocation: '石家庄市新华区门神小区',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市开发区建材城西路100楼',
        endLocation: '石家庄市正定南门',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市鹿泉区建材城西路100楼',
        endLocation: '石家庄市栾城',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市新城大道',
        endLocation: '石家庄市正定县大佛寺',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市裕华区神兴小区',
        endLocation: '石家庄市桥西区红旗小区',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市裕华区万达',
        endLocation: '石家庄市长安区万达',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市新华区市六院',
        endLocation: '石家庄市长安区市四院',
        time: '2021-08-09 01:00:02'
      }, {
        startLocation: '石家庄市开发区中国电信省公司',
        endLocation: '石家庄市新华区电信分公司',
        time: '2021-08-09 01:00:02'
      }],
      orderData: {
        day365: {
          orders: '20,301,987',
          amount: '99834'
        },
        day90: {
          orders: '301,987',
          amount: '9834'
        },
        day30: {
          orders: '1,987',
          amount: '3834'
        },
        day1: {
          orders: '987',
          amount: '834'
        }
      },
      nowOrderData: 'day365',
      timer: undefined,
      index: 0,
      arr: ['day365', 'day90', 'day30', 'day1'],
      lineData: {
        year: [[24, 40, 101, 134, 90, 230, 210, 230, 120, 230, 210, 120], [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79]],
        quarter: [[23, 75, 12, 97, 21, 67, 98, 21, 43, 64, 76, 38], [43, 31, 65, 23, 78, 21, 82, 64, 43, 60, 19, 34]],
        month: [[34, 87, 32, 76, 98, 12, 32, 87, 39, 36, 29, 36], [56, 43, 98, 21, 56, 87, 43, 12, 43, 54, 12, 98]],
        week: [[43, 73, 62, 54, 91, 54, 84, 43, 86, 43, 54, 53], [32, 54, 34, 87, 32, 45, 62, 68, 93, 54, 54, 24]]
      },
      lineType: 'year',
      lineTimer: undefined,
      lineArr: ['year', 'quarter', 'month', 'week'],
      lineIndex: 0,
      hotType: 'beijing',
      hotArr: ['beijing', 'hebei', 'shanghai', 'jiangsu', 'shandong'],
      hotIndex: 0,
      hotData: {
        beijing: [{
          name: '张三',
          num: '9,086'
        }, {
          name: '李四',
          num: '8,341'
        }, {
          name: '王五',
          num: '7,407'
        }, {
          name: '赵六',
          num: '6,080'
        }, {
          name: '张三',
          num: '6,724'
        }, {
          name: '张三',
          num: '2,170'
        }],
        hebei: [{
          name: '张三',
          num: '9,086'
        }, {
          name: '李四',
          num: '8,341'
        }, {
          name: '王五',
          num: '7,407'
        }, {
          name: '赵六',
          num: '6,080'
        }, {
          name: '张三',
          num: '6,724'
        }, {
          name: '张三',
          num: '2,170'
        }],
        shanghai: [{
          name: '张三',
          num: '9,086'
        }, {
          name: '李四',
          num: '8,341'
        }, {
          name: '王五',
          num: '7,407'
        }, {
          name: '赵六',
          num: '6,080'
        }, {
          name: '张三',
          num: '6,724'
        }, {
          name: '张三',
          num: '2,170'
        }],
        jiangsu: [{
          name: '张三',
          num: '9,086'
        }, {
          name: '李四',
          num: '8,341'
        }, {
          name: '王五',
          num: '7,407'
        }, {
          name: '赵六',
          num: '6,080'
        }, {
          name: '张三',
          num: '6,724'
        }, {
          name: '张三',
          num: '2,170'
        }],
        shandong: [{
          name: '张三',
          num: '9,086'
        }, {
          name: '李四',
          num: '8,341'
        }, {
          name: '王五',
          num: '7,407'
        }, {
          name: '赵六',
          num: '6,080'
        }, {
          name: '张三',
          num: '6,724'
        }, {
          name: '张三',
          num: '2,170'
        }]
      }
    };
  },
  computed: {
    seamlessScrollOption: function seamlessScrollOption() {
      return {
        step: 0.2,
        // 数值越大速度滚动越快
        limitMoveNum: 11,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  mounted: function mounted() {
    this.init();
    this.initPie();
    this.initBar();
    this.initOrderData();
    this.initLine();
    this.lineTimeout();
    this.initGauge();
    this.hotTimeout();
    this.init_map();
  },
  methods: {
    init_map: function init_map() {
      var map = new AMap.Map('container', {
        resizeEnable: true,
        center: [114.518097, 38.04948],
        zoom: 11,
        mapStyle: 'amap://styles/darkblue'
      });
      var heatmap;
      map.plugin(['AMap.Heatmap'], function () {
        //初始化heatmap对象
        heatmap = new AMap.Heatmap(map, {
          radius: 25,
          //给定半径
          opacity: [0, 0.8]
          /*,
          gradient:{
          0.5: 'blue',
          0.65: 'rgb(117,211,248)',
          0.7: 'rgb(0, 255, 0)',
          0.9: '#ffea00',
          1.0: 'red'
          }
          */
        });
        //设置数据集：该数据为石家庄部分“公园”数据
        heatmap.setDataSet({
          data: [{
            lng: 114.472176,
            lat: 38.062095,
            count: 100
          }, {
            lng: 114.547202,
            lat: 38.065277,
            count: 11
          }, {
            lng: 114.526505,
            lat: 38.026516,
            count: 12
          }, {
            lng: 114.523918,
            lat: 38.057322,
            count: 13
          }, {
            lng: 114.573936,
            lat: 38.026744,
            count: 14
          }, {
            lng: 114.452772,
            lat: 38.015828,
            count: 150
          }, {
            lng: 114.526793,
            lat: 38.039136,
            count: 160
          }, {
            lng: 114.534123,
            lat: 38.087433,
            count: 17
          }, {
            lng: 114.506527,
            lat: 38.054253,
            count: 18
          }, {
            lng: 114.49316,
            lat: 38.061754,
            count: 19
          }],
          max: 100
        });
      });
    },
    init: function init() {
      // 1、页面一加载就要知道页面宽度计算
      var setFont = function setFont() {
        // 因为要定义变量可能和别的变量相互冲突，污染，所有用自调用函数
        var html = document.documentElement; // 获取html
        // 获取宽度
        var width = html.clientWidth;

        // 判断
        if (width < 1024) width = 1024;
        if (width > 1920) width = 1920;
        // 设置html的基准值
        var fontSize = width / 80 + 'px';
        // 设置给html
        html.style.fontSize = fontSize;
      };
      setFont();
      // 2、页面改变的时候也需要设置
      // 尺寸改变事件
      window.onresize = function () {
        setFont();
      };
    },
    initPie: function initPie() {
      var myChart = echarts.init(document.getElementById('pie'));
      var option = {
        // 控制提示
        tooltip: {
          // 非轴图形，使用item的意思是放到数据对应图形上触发提示
          trigger: 'item',
          // 格式化提示内容：
          // a 代表图表名称 b 代表数据名称 c 代表数据  d代表  当前数据/总数据的比例
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        // 控制图表
        series: [{
          // 图表名称
          name: '地区',
          // 图表类型
          type: 'pie',
          // 南丁格尔玫瑰图 有两个圆  内圆半径10%  外圆半径70%
          // 百分比基于  图表DOM容器的半径
          radius: ['10%', '70%'],
          // 图表中心位置 left 50%  top 50% 距离图表DOM容器
          center: ['50%', '50%'],
          // 半径模式，另外一种是 area 面积模式
          roseType: 'radius',
          // 数据集 value 数据的值 name 数据的名称
          data: [{
            value: 20,
            name: '裕华区'
          }, {
            value: 5,
            name: '长安区'
          }, {
            value: 15,
            name: '新华区'
          }, {
            value: 25,
            name: '桥西区'
          }, {
            value: 20,
            name: '其他'
          }],
          //文字调整
          label: {
            fontSize: 10
          },
          //引导线
          labelLine: {
            length: 8,
            length2: 10
          }
        }],
        color: ['#006cff', '#60cda0', '#ed8884', '#ff9f7f', '#0096ff', '#9fe6b8', '#32c5e9', '#1d9dff']
      };
      myChart.setOption(option);
    },
    initBar: function initBar() {
      // 中间省略的数据  准备三项
      var myechart = echarts.init(document.getElementById('bar'));
      var item = {
        name: '',
        value: 1200,
        // 柱子颜色
        itemStyle: {
          color: '#254065'
        },
        // 鼠标经过柱子颜色
        emphasis: {
          itemStyle: {
            color: '#254065'
          }
        },
        // 工具提示隐藏
        tooltip: {
          extraCssText: 'opacity:0'
        }
      };
      var option = {
        // 工具提示
        tooltip: {
          // 触发类型  经过轴触发axis  经过轴触发item
          trigger: item,
          // 轴触发提示才有效
          axisPointer: {
            // 默认为直线，可选为：'line' 线效果 | 'shadow' 阴影效果
            type: 'shadow'
          }
        },
        // 图表边界控制
        grid: {
          // 距离 上右下左 的距离
          left: '0',
          right: '3%',
          bottom: '3%',
          top: '5%',
          // 大小是否包含文本【类似于boxsizing】
          containLabel: true,
          //显示边框
          show: true,
          //边框颜色
          borderColor: 'rgba(0, 240, 255, 0.3)'
        },
        // 控制x轴
        xAxis: [{
          // 使用类目，必须有data属性
          type: 'category',
          // 使用 data 中的数据设为刻度文字
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          // 刻度设置
          axisTick: {
            // true意思：图形在刻度中间
            // false意思：图形在刻度之间
            alignWithLabel: false,
            show: false
          },
          //文字
          axisLabel: {
            color: '#4c9bfd'
          }
        }],
        // 控制y轴
        yAxis: [{
          // 使用数据的值设为刻度文字
          type: 'value',
          axisTick: {
            // true意思：图形在刻度中间
            // false意思：图形在刻度之间
            alignWithLabel: false,
            show: false
          },
          //文字
          axisLabel: {
            color: '#4c9bfd'
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 240, 255, 0.3)'
            }
          }
        }],
        // 控制x轴
        series: [{
          // series配置
          // 颜色
          itemStyle: {
            // 提供的工具函数生成渐变颜色
            color: new echarts.graphic.LinearGradient(
            // (x1,y2) 点到点 (x2,y2) 之间进行渐变
            0, 0, 0, 1, [{
              offset: 0,
              color: '#00fffb'
            },
            // 0 起始颜色
            {
              offset: 1,
              color: '#0061ce'
            } // 1 结束颜色
            ])
          },
          // 图表数据名称
          name: '用户统计',
          // 图表类型
          type: 'bar',
          // 柱子宽度
          barWidth: '60%',
          // 数据
          data: [2100, 1900, 1700, 1560, 1400, 1400, 1400, 1400, 900, 750, 600, 480, 240]
        }]
      };
      myechart.setOption(option);
    },
    initOrderData: function initOrderData() {
      var _this = this;
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this.index++;
        if (_this.index > 3) {
          _this.index = 0;
        }
        _this.nowOrderData = _this.arr[_this.index];
        _this.initOrderData();
      }, 5000);
    },
    setOrderData: function setOrderData(val) {
      this.nowOrderData = val;
    },
    initLine: function initLine() {
      this.myechart = echarts.init(document.getElementById('line'));
      this.lineOption = {
        //鼠标提示工具
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          // 类目类型
          type: 'category',
          // x轴刻度文字
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisTick: {
            show: false //去除刻度线
          },
          axisLabel: {
            color: '#4c9bfd' //文本颜色
          },
          axisLine: {
            show: false //去除轴线
          },
          boundaryGap: false //去除轴内间距
        },
        yAxis: {
          // 数据作为刻度文字
          type: 'value',
          axisTick: {
            show: false //去除刻度线
          },
          axisLabel: {
            color: '#4c9bfd' //文本颜色
          },
          axisLine: {
            show: false //去除轴线
          },
          boundaryGap: false //去除轴内间距
        },
        //图例组件
        legend: {
          textStyle: {
            color: '#4c9bfd' // 图例文字颜色
          },
          right: '10%' //距离右边10%
        },
        // 设置网格样式
        grid: {
          show: true,
          // 显示边框
          top: '20%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          borderColor: '#012f4a',
          // 边框颜色
          containLabel: true // 包含刻度文字在内
        },
        series: [{
          name: '订单数量',
          // 数据
          data: this.lineData[this.lineType][0],
          // 图表类型
          type: 'line',
          // 圆滑连接
          smooth: true,
          itemStyle: {
            color: '#00f2f1' // 线颜色
          }
        }, {
          name: '增长率',
          // 数据
          data: this.lineData[this.lineType][1],
          // 图表类型
          type: 'line',
          // 圆滑连接
          smooth: true,
          itemStyle: {
            color: '#ed3f35' // 线颜色
          }
        }]
      };
      this.myechart.setOption(this.lineOption);
    },
    setLineData: function setLineData(val) {
      this.lineType = val;
      this.lineOption.series[0].data = this.lineData[this.lineType][0];
      this.lineOption.series[1].data = this.lineData[this.lineType][1];
      this.myechart.setOption(this.lineOption);
    },
    lineTimeout: function lineTimeout() {
      var _this2 = this;
      this.lineTimer && clearTimeout(this.lineTimer);
      this.lineTimer = setTimeout(function () {
        _this2.lineIndex++;
        if (_this2.lineIndex > 3) {
          _this2.lineIndex = 0;
        }
        _this2.lineType = _this2.lineArr[_this2.lineIndex];
        _this2.lineOption.series[0].data = _this2.lineData[_this2.lineType][0];
        _this2.lineOption.series[1].data = _this2.lineData[_this2.lineType][1];
        _this2.myechart.setOption(_this2.lineOption);
        _this2.lineTimeout();
      }, 5000);
    },
    initGauge: function initGauge() {
      var option = {
        series: [{
          type: 'pie',
          radius: ['130%', '150%'],
          // 放大图形
          center: ['50%', '80%'],
          // 往下移动  套住75%文字
          label: {
            show: false
          },
          startAngle: 180,
          hoverOffset: 0,
          // 鼠标经过不变大
          data: [{
            value: 100,
            itemStyle: {
              // 颜色渐变#00c9e0->#005fc1
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#00c9e0'
                }, {
                  offset: 1,
                  color: '#005fc1'
                }]
              }
            }
          }, {
            value: 100,
            itemStyle: {
              color: '#12274d'
            }
          },
          // 颜色#12274d

          {
            value: 200,
            itemStyle: {
              color: 'transparent'
            }
          } // 透明隐藏第三块区域
          ]
        }]
      };
      var myechart = echarts.init(document.getElementById('gauge'));
      myechart.setOption(option);
    },
    setHotData: function setHotData(val) {
      this.hotType = val;
    },
    hotTimeout: function hotTimeout() {
      var _this3 = this;
      this.hotTime && clearTimeout(this.hotTime);
      this.hotTime = setTimeout(function () {
        _this3.hotIndex++;
        if (_this3.hotIndex > 4) {
          _this3.hotIndex = 0;
        }
        _this3.hotType = _this3.hotArr[_this3.hotIndex];
        _this3.hotTimeout();
      }, 5000);
    }
  }
};