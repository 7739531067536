//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTenantList } from '@/api/manager/tenant';
import { company_get_drop_list } from '@/api/manager/company';
// import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  name: 'SelectItem',
  // components: { Treeselect },
  props: {
    defaultTenant: {
      type: Boolean,
      default: false
    },
    clearTenant: {
      type: Boolean,
      default: true
    },
    defaultCompany: {
      type: Boolean,
      default: false
    },
    clearCompany: {
      type: Boolean,
      default: true
    }
  },
  // props: ['defaultTenant','clear'],
  data: function data() {
    return {
      // 查询参数
      queryParams: {
        tenantId: undefined,
        companyId: undefined
      },
      // 租户列表
      tenantList: [],
      companyList: []
      // 公司树选项
      // companyOptions: [],
    };
  },
  created: function created() {
    this.getTenant();
  },
  methods: {
    resetQuery: function resetQuery() {
      this.companyList = [];
      this.queryParams.tenantId = undefined;
      this.queryParams.companyId = undefined;
    },
    resetDefault: function resetDefault() {
      this.getTenant();
    },
    getTenant: function getTenant() {
      var _this = this;
      getTenantList().then(function (res) {
        _this.tenantList = res.data;
        if (_this.defaultTenant || _this.tenantList.length == 1) {
          _this.queryParams.tenantId = _this.tenantList[0].id;
          _this.changeTenant();
        }
      });
    },
    /** 查询公司列表 */getCompany: function getCompany() {
      var _this2 = this;
      company_get_drop_list({
        tenantId: this.queryParams.tenantId
      }).then(function (response) {
        _this2.companyList = response.data;
        if (_this2.defaultCompany) {
          _this2.queryParams.companyId = _this2.companyList[0].id;
        } else {
          _this2.queryParams.companyId = undefined;
        }
        _this2.changeCompany();
      });
    },
    changeTenant: function changeTenant() {
      if (this.queryParams.tenantId) {
        this.getCompany();
      } else {
        this.companyList = [];
        this.queryParams.companyId = undefined;
        this.$emit('upSelect', this.queryParams);
      }
    },
    changeCompany: function changeCompany() {
      this.$emit('upSelect', this.queryParams);
    } // /** 转换公司数据结构 */
    // normalizer(node) {
    //     if (node.children && !node.children.length) {
    //         delete node.children
    //     }
    //     return {
    //         value: node.id,
    //         label: node.companyAbbreviation,
    //         children: node.children,
    //     }
    // },
    // filterChildren(value) {
    //     var data = value
    //     data.forEach((el) => {
    //         if (el.children && el.children.length) {
    //             this.filterChildren(el.children)
    //             return
    //         } else {
    //             el.value = el.id
    //         }
    //     })
    //     return data
    // },
  }
};