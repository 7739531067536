// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icomoon.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./icomoon.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./icomoon.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./icomoon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
exports.push([module.id, "@font-face {\n  font-family: 'icomoon';\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src:  url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n  font-weight: normal;\n  font-style: normal;\n}\n[class^=\"icon-\"], [class*=\" icon-\"] {\n  /* use !important to prevent issues with browser extensions that change fonts */\n  font-family: 'icomoon' !important;\n  speak: none;\n  font-style: normal;\n  font-weight: normal;\n  font-variant: normal;\n  text-transform: none;\n  line-height: 1;\n\n  /* Better Font Rendering =========== */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-dot:before {\n  content: \"\\e900\";\n}\n.icon-cup1:before {\n  content: \"\\e901\";\n}\n.icon-cup2:before {\n  content: \"\\e902\";\n}\n.icon-cup3:before {\n  content: \"\\e903\";\n}\n.icon-clock:before {\n  content: \"\\e904\";\n}\n.icon-down:before {\n  content: \"\\e905\";\n}\n.icon-cube:before {\n  content: \"\\e906\";\n}\n.icon-plane:before {\n  content: \"\\e907\";\n}\n.icon-train:before {\n  content: \"\\e908\";\n}\n.icon-bus:before {\n  content: \"\\e909\";\n}\n.icon-bag:before {\n  content: \"\\e90a\";\n}\n.icon-up:before {\n  content: \"\\e90b\";\n}\n", ""]);
// Exports
module.exports = exports;
