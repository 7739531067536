import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from 'js-cookie';
import md5 from '@/utils/md5';
export default {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入正确的用户名'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length == 0) {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      rememberMe: false,
      loginForm: {
        userName: '',
        password: ''
      },
      passwordMD5: md5,
      loginRules: {
        userName: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCookie();
  },
  methods: {
    getCookie: function getCookie() {
      var username = Cookies.get('username');
      var password = Cookies.get('password');
      var rememberMe = Cookies.get('rememberMe');
      this.loginForm = {
        userName: username === undefined ? this.loginForm.userName : username,
        password: password === undefined ? this.loginForm.password : password
      };
      this.rememberMe = rememberMe === undefined ? false : true;
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          if (_this2.rememberMe) {
            Cookies.set('username', _this2.loginForm.userName, {
              expires: 30
            });
            Cookies.set('password', _this2.loginForm.password, {
              expires: 30
            });
            Cookies.set('rememberMe', _this2.rememberMe, {
              expires: 30
            });
          } else {
            Cookies.remove('username');
            Cookies.remove('password');
            Cookies.remove('rememberMe');
          }
          _this2.$store.dispatch('user/login', {
            username: _this2.loginForm.userName,
            password: _this2.passwordMD5(_this2.loginForm.password)
          }).then(function () {
            _this2.$router.push({
              path: _this2.redirect || '/'
            }).catch(function () {});
            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};