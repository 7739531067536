import request from '@/utils/request';
export function getInfo(data) {
  return request({
    url: 'manager/user-info',
    method: 'get',
    params: data
  });
}
export function subscribe_company_id(data) {
  return request({
    url: 'manager/subscribe-company-id',
    method: 'get',
    params: data
  });
}