import "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/core-js/modules/es.array.iterator.js";
import "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/core-js/modules/es.promise.js";
import "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/core-js/modules/es.object.assign.js";
import "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import locale from "element-ui/lib/locale/lang/en"; // lang i18n

import '@/styles/index.scss'; // global css

import { handleTree, parseTime, resetForm, addDateRange } from '@/utils/ruoyi';
Vue.prototype.handleTree = handleTree;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
import App from './App';
import store from './store';
import router from './router';

// import VueSeamlessScroll from 'vue-seamless-scroll'
// Vue.use(VueSeamlessScroll)

import directive from './directive'; // directive
Vue.use(directive);
import plugins from './plugins'; // plugins
Vue.use(plugins);
import '@/icons'; // icon
import '@/permission'; // permission control
import * as voicePromptFun from './utils/voicePrompt';
Vue.prototype.voicePrompt = voicePromptFun.voicePrompt; //语音提醒
import * as filters from './filters'; // global filters
// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
// 防抖处理-立即执行
// const on = Vue.prototype.$on
// Vue.prototype.$on = function (event, func) {
//   let timer;
//   let flag = true;
//   let newFunc = func
//   if (event == 'click') {
//     newFunc = function () {
//       if(flag) {
//         func.apply(this, arguments)
//         flag = false
//       }
//       clearTimeout(timer)
//       timer = setTimeout(function () {
//         flag = true
//       }, 1000)
//     }
//   }
//   on.call(this, event, newFunc)
// }
// set ElementUI lang to EN
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});