import _interopRequireWildcard from "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// import Vue from 'vue'
// import Router from 'vue-router'

// Vue.use(Router)

// /* Layout */
// import Layout from '@/layout'

// /* Router Modules */
// import allot from './modules/allot'
// import order from './modules/order'
// import driver from './modules/driver'
// import financial from './modules/financial'
// import tool from './modules/tool'
// import statistics from './modules/statistics'
// import message from './modules/message'
// import coupon from './modules/coupon'
// import user from './modules/user'
// import area from './modules/area'
// import business_management from './modules/business_management'
// import system from './modules/system'

// /**
//  * Note: sub-menu only appear when route children.length >= 1
//  * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
//  *
//  * hidden: true                   if set true, item will not show in the sidebar(default is false)
//  * alwaysShow: true               if set true, will always show the root menu
//  *                                if not set alwaysShow, when item has more than one children route,
//  *                                it will becomes nested mode, otherwise not show the root menu
//  * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
//  * name:'router-name'             the name is used by <keep-alive> (must set!!!)
//  * meta : {
//     roles: ['admin','editor']    control the page roles (you can set multiple roles)
//     title: 'title'               the name show in sidebar and breadcrumb (recommend set)
//     icon: 'svg-name'             the icon show in the sidebar
//     breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
//     activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
//   }
//  */

// /**
//  * constantRoutes
//  * a base page that does not have permission requirements
//  * all roles can be accessed
//  */
// export const constantRoutes = [
//     {
//         path: '/create',
//         component: () => import('@/views/order/Create'),
//         hidden: true
//     },
//     {
//         path: '/login',
//         component: () => import('@/views/login/index'),
//         hidden: true
//     },

//     {
//         path: '/404',
//         component: () => import('@/views/404'),
//         hidden: true
//     },
//     {
//         path: '/test',
//         component: () => import('@/views/test/index'),
//         hidden: true
//     },
//     {
//         path: '/analysis',
//         component: () => import('@/views/analysis/analysis'),
//         hidden: true
//     },
//     {
//         path: '/people',
//         component: () => import('@/views/analysis/people'),
//         hidden: true
//     },
//     {
//         path: '/dispatch',
//         component: () => import('@/views/order/dispatch'),
//         hidden: true
//     },
//     {
//         path: '/',
//         component: Layout,
//         redirect: '/dashboard',
//         children: [
//             {
//                 path: 'dashboard',
//                 name: 'Dashboard',
//                 component: () => import('@/views/dashboard/index'),
//                 meta: { title: '首页', icon: 'dashboard' }
//             }
//         ]
//     },
//     // 派单管理
//     // allot,
//     {
//         path: 'dispatch',
//         component: Layout,
//         hidden: false,
//         children: [
//             {
//                 path: process.env.VUE_APP_BASE_URL + 'dispatch',
//                 meta: { title: '司机调度', icon: 'link' }
//             }
//         ]
//     }

//     // {
//     //     path: 'external-link',
//     //     component: Layout,
//     //     hidden: true,
//     //     children: [
//     //         {
//     //             path: 'http://192.168.31.47:8080/people',
//     //             meta: { title: 'External Link', icon: 'link' }
//     //         }
//     //     ]
//     // }
// ]
// /**
//  * asyncRoutes
//  * the routes that need to be dynamically loaded based on user roles
//  */
// export const asyncRoutes = [
//     // 订单管理
//     order,
//     // 司机管理
//     driver,
//     // 用户管理
//     user,
//     // 统计
//     statistics,
//     // 财务管理
//     financial,
//     // 优惠券
//     coupon,
//     // 区域设置
//     area,
//     // 业务管理
//     business_management,
//     // 工具
//     tool,
//     // 公告
//     message,
//     // 系统管理
//     system,
//     // 404 page must be placed at the end !!!
//     { path: '*', redirect: '/404', hidden: true }
// ]

// const createRouter = () =>
//     new Router({
//         mode: 'history', // require service support
//         base: '/',
//         scrollBehavior: () => ({ y: 0 }),
//         routes: constantRoutes
//     })

// const router = createRouter()

// // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//     const newRouter = createRouter()
//     router.matcher = newRouter.matcher // reset router
// }

// export default router
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export var constantRoutes = [{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
},
//     {
//     path: 'dispatch',
//     component: Layout,
//     hidden: false,
//     children: [
//         {
//             path: process.env.VUE_APP_BASE_URL + 'dispatch',
//             meta: { title: '司机调度', icon: 'link' }
//         }
//     ]
// },
// 订单管理
// order,
// 司机管理
// driver,
// 用户管理
// user,
// 统计
// statistics,
// 财务管理
// financial,
// 优惠券
// coupon,
// 区域设置
// area,
// 业务管理
// business_management,
// 工具
// tool,
// 公告
// message,
// {
//     path: '/business_management',
//     component: Layout,
//     redirect: '/business_management/billing_settings',
//     name: 'BusinessManagement',
//     alwaysShow: true,
//     meta: {
//         title: '业务管理',
//         icon: 'component',
//         roles: []
//     },
//     children: [
//         {
//             path: 'dirver_cash_flow',
//             component: () => import('@/views/financial/dirver_cash_flow/index'),
//             name: 'Driverbusiness',
//             meta: { title: '司机业务', icon: 'list' }
//         }
//     ]
// },

{
  path: '/create',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/order/Create'));
    });
  },
  hidden: true
}, {
  path: '/addOrder',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/order/addOrder'));
    });
  },
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/test',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/test/index'));
    });
  },
  hidden: true
},
// {
//     path: '/analysis',
//     component: () => import('@/views/analysis/analysis'),
//     hidden: true
// },
// {
//     path: '/people',
//     component: () => import('@/views/analysis/people'),
//     hidden: true
// },
{
  path: '/dispatch',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/order/dispatch'));
    });
  },
  hidden: true
}, {
  path: '/heatmap',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/heatmap/index'));
    });
  },
  hidden: true
}];

// 动态路由，基于用户权限动态去加载
export var dynamicRoutes = [];
var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  console.log('重置路由', router);
}

// 防止连续点击多次路由报错
// let routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return routerPush.call(this, location).catch(err => err)
// }

export default new Router({
  mode: 'history',
  // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});