import _interopRequireWildcard from "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import auth from '@/plugins/auth';
import router, { constantRoutes, dynamicRoutes } from '@/router';
import Layout from '@/layout/index';
import ParentView from '@/components/ParentView';
import InnerLink from '@/layout/components/InnerLink';
import { getRouters } from '@/api/manager/user_menu';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
// export function filterAsyncRoutes(routes, roles) {
//     const res = []

//     routes.forEach(route => {
//         const tmp = { ...route }
//         if (hasPermission(roles, tmp)) {
//             if (tmp.children) {
//                 tmp.children = filterAsyncRoutes(tmp.children, roles)
//             }
//             res.push(tmp)
//         }
//     })

//     return res
// }
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return asyncRouterMap.filter(function (route) {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout;
      } else if (route.component === 'ParentView') {
        route.component = ParentView;
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route['children'];
      delete route['redirect'];
    }
    return true;
  });
}
// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}
function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }
    children = children.concat(el);
  });
  return children;
}
export var loadView = function loadView(view) {
  if (process.env.NODE_ENV === 'development') {
    return function (resolve) {
      return require(["@/views/".concat(view)], resolve);
    };
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return function () {
      return Promise.resolve("@/views/".concat(view)).then(function (s) {
        return _interopRequireWildcard(require(s));
      });
    };
  }
  // return () => import(`@/views/${view}`)
  // return resolve => require([`@/views/${view}`], resolve)
  // () => import('@/views/system/user/index')
};
var state = {
  routes: [],
  addRoutes: [],
  defaultRoutes: [],
  topbarRouters: [],
  sidebarRouters: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_DEFAULT_ROUTES: function SET_DEFAULT_ROUTES(state, routes) {
    state.defaultRoutes = constantRoutes.concat(routes);
  },
  SET_TOPBAR_ROUTES: function SET_TOPBAR_ROUTES(state, routes) {
    state.topbarRouters = routes;
  },
  SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routes) {
    state.sidebarRouters = routes;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // 向后端请求路由数据
      getRouters().then(function (res) {
        // const res = {
        //     msg: '操作成功',
        //     code: 200,
        //     data: [
        //         {
        //             name: 'System',
        //             path: '/system',
        //             hidden: false,
        //             redirect: 'noRedirect',
        //             component: 'Layout',
        //             alwaysShow: true,
        //             meta: { title: '系统管理', icon: 'system', noCache: false, link: null },
        //             children: [
        //                 {
        //                     name: 'Tenants',
        //                     path: 'tenants',
        //                     hidden: false,
        //                     component: 'system/tenants/index',
        //                     meta: { title: '租户管理', icon: 'user', noCache: false, link: null }
        //                 },
        //                 {
        //                     name: 'Company',
        //                     path: 'company',
        //                     hidden: false,
        //                     component: 'system/company/index',
        //                     meta: { title: '公司管理', icon: 'user', noCache: false, link: null }
        //                 },
        //                 {
        //                     name: 'User',
        //                     path: 'user',
        //                     hidden: false,
        //                     component: 'system/user/index',
        //                     meta: { title: '用户管理', icon: 'user', noCache: false, link: null }
        //                 },
        //                 {
        //                     name: 'Menu',
        //                     path: 'menu',
        //                     hidden: false,
        //                     component: 'system/menu/index',
        //                     meta: { title: '菜单管理', icon: 'tree-table', noCache: false, link: null }
        //                 }
        //             ]
        //         }
        //     ]
        // }
        var sdata = JSON.parse(JSON.stringify(res.data));
        var rdata = JSON.parse(JSON.stringify(res.data));
        var sidebarRoutes = filterAsyncRouter(sdata);
        var rewriteRoutes = filterAsyncRouter(rdata, false, true);
        var asyncRoutes = filterDynamicRoutes(dynamicRoutes);
        rewriteRoutes.push({
          path: '*',
          redirect: '/404',
          hidden: true
        });
        router.addRoutes(asyncRoutes);
        commit('SET_ROUTES', rewriteRoutes);
        commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes));
        commit('SET_DEFAULT_ROUTES', sidebarRoutes);
        commit('SET_TOPBAR_ROUTES', sidebarRoutes);
        resolve(rewriteRoutes);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};