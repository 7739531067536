import request from '@/utils/request';
// 根据客户手机号获取客户信息
export function customerInfoPhone(data) {
  return request({
    url: 'manager/customer/info/phone',
    method: 'get',
    params: data
  });
}
export function rechargePage(data) {
  return request({
    url: 'manager/customer/recharge/page',
    method: 'get',
    params: data
  });
}
export function wallet(data) {
  return request({
    url: 'manager/customer/recharge/wallet',
    method: 'get',
    params: data
  });
}
export function customer_recharge(data) {
  return request({
    url: 'manager/customer/recharge',
    method: 'post',
    data: data
  });
}
// 添加黑名单
export function black_list_add(data) {
  return request({
    url: 'manager/customer/black-list/add',
    method: 'post',
    data: data
  });
}
// 移除黑名单
export function black_list_remove(data) {
  return request({
    url: 'manager/customer/black-list/remove',
    method: 'post',
    data: data
  });
}
// 导出客户列表Excel
export function exportList(data) {
  return request({
    url: '/driving/manager/customer/export',
    method: 'get',
    params: data,
    responseType: 'blob'
  });
}