import request from '@/utils/request';
// 财务统计列表
export function statistics_finance_list(data) {
  return request({
    url: 'manager/statistics/finance-list',
    method: 'get',
    params: data
  });
}
// 财务统计汇总
export function statistics_finance_total(data) {
  return request({
    url: 'manager/statistics/finance-total',
    method: 'get',
    params: data
  });
}
// 财务统计汇总
export function statistics_export(data) {
  return request({
    url: 'manager/statistics/export',
    method: 'get',
    params: data,
    responseType: 'blob'
  });
}
// 首页统计

export function statistics_index(data) {
  return request({
    url: 'manager/statistics/index',
    method: 'get',
    params: data
  });
}
export function driver_online_time(data) {
  return request({
    url: 'manager/statistics/driver-online-time',
    method: 'get',
    params: data
  });
}