import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
import Decimal from 'decimal.js';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * 司机类型
 * @param {String} string
 */
export function getDriverType(string) {
  var typeMap = {
    FULL_TIME: '全职',
    PART_TIME: '兼职'
  };
  return typeMap[string];
}
/**
 * 司机状态statusFilter
 */
export function statusFilter(string) {
  var typeMap = {
    NOT_PERFECT: ' 待完善',
    WAIT_AUDIT: '待审核',
    ON_JOB: '在职',
    FROZEN: '冻结',
    REFUSED: '拒绝',
    DIMISSION: '离职'
  };
  return typeMap[string];
}
/**
 * 审批状态
 */
export function approveStatusFilter(string) {
  var typeMap = {
    NOT_PERFECT: '待完善',
    WAIT_AUDIT: '待审核',
    PASS: '通过',
    REFUSED: '拒绝'
  };
  return typeMap[string];
}
/**
 * 提现状态
 */
export function withdrawalStatusFilter(string) {
  var typeMap = {
    UNDER_REVIEW: '审核中',
    PASS: '提现成功',
    REFUSE: '拒绝',
    NOT_PASS: '提现失败'
  };
  return typeMap[string];
}
/**
 * 客户提现状态
 */
export function withdrawalStatusFilter1(string) {
  var typeMap = {
    WAIT: '待审核',
    SUCCESS: '提现成功',
    FAIL: '拒绝',
    ERROR: '提现失败'
  };
  return typeMap[string];
}
/**
 * 收入类型
 */
export function incomeTypeFilter(string) {
  var typeMap = {
    INVITE_BONUS: '邀约奖励金',
    CONTRIBUTION_BONUS: '贡献奖励金',
    ONLINE_REVENUE: '线上收入',
    COUPON_SUBSIDY: '优惠券补助',
    PERIOD_SUBSIDY: '时段补助'
  };
  return typeMap[string];
}
/**
 * 时段奖励金类型
 */
export function subsidyTypeFilter(string) {
  var typeMap = {
    DAY_SUBSIDY: '白班补助',
    NIGHT_SUBSIDY: '夜班补助'
  };
  return typeMap[string];
}
/**
 * 订单状态
 */
export function orderStatusFilter(string) {
  var typeMap = {
    NEW: '新单',
    RECEIVE: '已接单',
    DISPATCHED: '已派单',
    IN_SERVICE: '服务中',
    COMPLETED_SERVICE: '服务完成',
    COMPLETED: '已完成',
    CANCELLED: '已消单'
  };
  return typeMap[string];
  // if (row.orderStatus === 'COMPLETED') {
  //     return row.isPay ? '已完成' : '未支付'
  // } else {
  //     return typeMap[row.orderStatus]
  // }
}
// 订单状态（创单页面专用）
export function orderStatusFilter1(string) {
  var typeMap = {
    NEW: '新单',
    COMPLETED_SERVICE: '未支付'
  };
  return typeMap[string];
  // if (row.orderStatus === 'COMPLETED') {
  //     return row.isPay ? '已完成' : '未支付'
  // } else {
  //     return typeMap[row.orderStatus]
  // }
}
// 订单支付方式
export function payTypeFilter(string) {
  var typeMap = {
    OFFLINE: '线下支付',
    WX: '微信',
    BALANCE: '余额',
    WX_AND_BALANCE: '组合支付'
  };
  return typeMap[string];
}

// 行程状态
export function tripStatusFilter(string) {
  var typeMap = {
    GO_TO_APPT: '前往预约地',
    ARRIVE_APPT: '到达预约地',
    GO_TO_DESTN: '前往目的地',
    ARRIVE_DESTN: '到达目的地'
  };
  return typeMap[string];
}

// 订单来源
export function channelSourceFilter(string) {
  var typeMap = {
    CUSTOMER_SERVICE: '客服创单',
    CUSTOMER: '客户创单',
    DRIVER: '司机创单',
    CUSTOMER_SCAN: '客户扫码创单',
    WX_GROUP: '企业微信群创单'
  };
  return typeMap[string];
}
export function billingModeFilter(string) {
  var typeMap = {
    FENCE: '电子围栏模式',
    MILEAGE: '里程模式',
    CHECK_CAR: '代检车'
  };
  return typeMap[string];
}
export function checkCarYearFilter(string) {
  var typeMap = {
    TEN_YEAR_DOWN: '十年以下',
    TEN_YEAR_UP: '十年以上'
  };
  return typeMap[string];
}
/**
 * @param {number} second
 */
export function secondToMinute(second) {
  return Math.ceil(second / 60);
}
// 订单来源
export function registeredChannelFilter(string) {
  var typeMap = {
    CUSTOMER_SERVICE: '客服创单',
    WAP: '小程序',
    APP: 'APP'
  };
  return typeMap[string];
}
// 优惠券类型
export function couponsTypeFilter(string) {
  var typeMap = {
    DEDUCT: '抵扣券'
  };
  return typeMap[string];
}
// 优惠券使用状态 couponsUseStatus
export function couponsUseStatusFilter(string) {
  var typeMap = {
    UNUSED: '未使用',
    USED: '已使用'
  };
  return typeMap[string];
}
// 优惠券状态 couponsStatus
export function couponsStatusFilter(string) {
  var typeMap = {
    NOT_EXPIRED: '未过期',
    EXPIRED: '过期',
    NOT_STARTED: '未开始'
  };
  return typeMap[string];
}
// 加盟商类型
export function operationalTypeFilter(string) {
  var typeMap = {
    PROPRIEATARY: '直营',
    LEAGUE: '加盟'
  };
  return typeMap[string];
}
// 用户优惠券状态
export function useStatusFilter(string) {
  var typeMap = {
    USED: '已使用',
    UNUSED: '未使用',
    GIVE: '已赠送'
  };
  return typeMap[string];
}
// 用户优惠券状态
export function timeFilter(string) {
  var typeMap = {
    YEAR: '月',
    MONTH: '日',
    DAY: '时'
  };
  return typeMap[string];
}
// 司机钱包变动类型
export function walletChangeCategoryFilter(string) {
  var typeMap = {
    INVITE_BONUS: '邀约奖励金',
    CONTRIBUTION_BONUS: '贡献奖励金',
    COUPON_SUBSIDY: '优惠券',
    ONLINE_REVENUE: '线上收入',
    PERIOD_SUBSIDY: '时段补助',
    PROMOTE_RECHARGE: '推广单奖励',
    PROMOTE_DEDUCTION: '推广单扣款',
    COUPON_CARD_SUBSIDY: '代驾卡补助',
    RECHARGE: '充值',
    WITHDRAW: '提现',
    INFORMATION_FEE: '包月信息费',
    ORDER_PERCENTAGE: '按单抽成扣费',
    PREMIUM: '按单保险扣费',
    REFUND: '退款',
    DEDUCTION: '后台扣款',
    OTHER_FEE: '其他费用'
  };
  return typeMap[string];
}
// 客户余额明细查询类型
export function customerChangeCategoryFilter(string) {
  var typeMap = {
    RECHARGE: '充值',
    ONLINE_REVENUE: '订单'
  };
  return typeMap[string];
}
export function toCNYFilter(val) {
  return Decimal.div(val, 100);
}
export function toFenFilter(val) {
  return Decimal.mul(val, 100);
}
export function monthpaymentTypeFilter(string) {
  var typeMap = {
    WX_PAY: '微信支付',
    BALANCE: '余额支付',
    WX_AND_BALANCE: '微信组合支付',
    ALI_AND_BALANCE: '支付宝组合支付',
    ALI_PAY: '支付宝支付'
  };
  return typeMap[string];
}
export function feeFilter(string) {
  var typeMap = {
    MARGIN: '保证金',
    CLOTHING_FEE: '服装费',
    EXAMINATION_FEE: '考试费',
    OTHER_FEE: '其他费用'
  };
  return typeMap[string];
}
export function setMealFilter(string) {
  var typeMap = {
    BY_ORDER: '按单',
    INCLUDE_INSURANCE: '包月含保险',
    MONTHLY_CONTRACT: '包月不含保险'
  };
  return typeMap[string];
}
export function recordTypeFilter(string) {
  var typeMap = {
    RECHARGE: '充值',
    ONLINE_DURATION: '在线时长',
    CREATE_ORDER: '创单',
    DISPATCH_ORDER: '派单',
    REJECTION_TIMEOUT: '拒单/超时',
    CLEAR: '清零',
    PROMOTE: '地推',
    PAY: '积分兑换'
  };
  return typeMap[string];
}
export function promoteTypeFilter(string) {
  var typeMap = {
    HOTEL: '饭店推广',
    OUTDOORS: '户外推广'
  };
  return typeMap[string];
}
export function auditStatusFilter(string) {
  var typeMap = {
    SUCCESS: '通过',
    FAIL: '拒绝',
    WAIT: '待审核'
  };
  return typeMap[string];
}
export function walletTypeFilter(string) {
  var typeMap = {
    DEDUCT: '支出',
    RECHARGE: '充值'
  };
  return typeMap[string];
}
export function refundTypeFilter(string) {
  var typeMap = {
    WAIT_AUDIT: '待审核',
    REFUNDED: '已退款',
    REFUSE: '拒绝'
  };
  return typeMap[string];
}
// 推广经理审核
export function applyPromotionTypeFilter(string) {
  var typeMap = {
    WAIT: '待审核',
    FAIL: '已拒绝',
    SUCCESS: '已通过'
  };
  return typeMap[string];
}
// 开票状态
export function refundTypeFilter1(string) {
  var typeMap = {
    WAIT: '待审核',
    SUCCESS: '通过',
    FAIL: '拒绝'
  };
  return typeMap[string];
}
withdrawalTypeFilter;
export function withdrawalTypeFilter(string) {
  var typeMap = {
    WX: '微信',
    BANK_CARD: '银行卡',
    ALI_PAY: '支付宝'
  };
  return typeMap[string];
}
export function setMealTypeFilter(string) {
  var typeMap = {
    MONTHLY_CONTRACT: '包月不含保险',
    INCLUDE_INSURANCE: '包月含保险',
    BY_ORDER: '按单'
  };
  return typeMap[string];
}
export function trackRecordStatusFilter(string) {
  var typeMap = {
    CANCELLED: '取消订单',
    WAITING_TIME: '司机超时未接单',
    MANUAL: '司机手动拒单'
  };
  return typeMap[string];
}
// 客户余额明细查询类型
export function customerTypeFilter(string) {
  var typeMap = {
    RECHARGE: '充值',
    DEDUCT: '支出'
  };
  return typeMap[string];
}
// 操作记录类型过滤
export function operationRecordTypeFilter(string) {
  var typeMap = {
    THAW: '解冻',
    FROZEN: '冻结',
    QUIT_JOB: '离职',
    ON_JOB: '在职',
    RETURN_DEPOSIT: '退押金',
    OPERATE_RECORD: '操作记录'
  };
  return typeMap[string];
}
// 包月缴费 monthPayEnum
export function monthPayEnumFilter(string) {
  var typeMap = {
    NEXT_MONTH: '次月',
    NEXT_DAY: '次日'
  };
  return typeMap[string];
}