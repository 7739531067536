import request from '@/utils/request';
// 热力图
export function thermalMap(data) {
  return request({
    url: 'manager/statistics/heat-map',
    method: 'get',
    params: data
  });
}
// 点聚合
export function pointAggregation(data) {
  return request({
    url: '/driving/manager/order/cancel_list',
    method: 'get',
    params: data
  });
}