import _objectSpread from "/var/lib/jenkins/workspace/pre_\u4EE3\u9A7E\u7BA1\u7406\u9875\u9762/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountTo from 'vue-count-to';
import LineChart from './components/LineChart.vue';
import PieChart from './components/PieChart.vue';
import RingChart from './components/RingChart.vue';
import SelectItem from '@/components/SelectItem';
import { statistics_index } from '@/api/manager/statistics';
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  components: {
    CountTo: CountTo,
    SelectItem: SelectItem,
    LineChart: LineChart,
    PieChart: PieChart,
    RingChart: RingChart
  },
  data: function data() {
    return {
      isInit: true,
      listQuery: {
        tenantId: undefined,
        companyId: undefined,
        localDate: undefined
      },
      value: undefined,
      // 时间选择器数据
      value1: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 3600 * 1000;
        }
      },
      orderData: {
        createOrderTotal: {
          title: '创单量（单）',
          value: 0
        },
        receivingOrderTotal: {
          title: '接单量（单）',
          value: 0
        },
        completeOrderTotal: {
          title: '完单量（单）',
          value: 0
        },
        cancelOrderTotal: {
          title: '销单量（单）',
          value: 0
        },
        payMoney: {
          title: '金额（元）',
          value: 0
        },
        orderCommission: {
          title: '订单抽成（元）',
          value: 0
        },
        orderInsurance: {
          title: '订单保险费（元）',
          value: 0
        },
        avgDuration: {
          title: '平均时长（分钟）',
          value: 0
        },
        avgMileage: {
          title: '平均公里（公里）',
          value: 0
        },
        avgMoney: {
          title: '平均客单价（元）',
          value: 0
        },
        avgOrder: {
          title: '人均单量（单）',
          value: 0
        }
      },
      driverData: {
        onlineDriver: {
          title: '上线司机（人）',
          value: 0
        },
        receivingDriver: {
          title: '接单司机（人）',
          value: 0
        },
        completeDriver: {
          title: '完单司机（人）',
          value: 0
        },
        refuseOrderTotal: {
          title: '司机拒单（次）',
          value: 0
        },
        onlineTimeLte60: {
          title: '上线时长≤1小时（人）',
          value: 0
        },
        onlineTimeGt60: {
          title: '上线时长>1小时（人）',
          value: 0
        },
        onJobDriver: {
          title: '在职司机（人）',
          value: 0
        },
        enterJobDriver: {
          title: '入职司机（人）',
          value: 0
        },
        quitJobDriver: {
          title: '离职司机（人）',
          value: 0
        }
      },
      pieChartData: [],
      ringChartData: [],
      lineChartData: {},
      yData2: [],
      yData3: [],
      yData4: []
    };
  },
  computed: _objectSpread({}, mapGetters(['user_type'])),
  mounted: function mounted() {
    var curDate = new Date(); //获取当前时间
    var datetime = new Date(curDate.getTime() - 24 * 60 * 60 * 1000); //获取前一天的时间
    var year = datetime.getFullYear(); //获取当前年份
    var month = datetime.getMonth() + 1; //获取当前月份
    if (month <= 9) {
      month = "0" + month;
    }
    var date = datetime.getDate(); //获取当前时间
    if (date <= 9) {
      date = "0" + date;
    }
    var startDate = year + "-" + month + "-" + date;
    if (this.user_type == 'SUPER_ADMIN' || this.user_type == 'PLATFORM') {
      // console.log(startDate)
      this.value1 = startDate;
      this.listQuery.localDate = startDate;
      this.getData();
    }
  },
  methods: {
    decimals: function decimals(item) {
      if (['金额（元）', '订单抽成（元）', '订单保险费（元）', '平均客单价（元）', '平均公里（公里）', '人均单量（单）'].indexOf(item) > -1) {
        return 2;
      } else return 0;
    },
    // 修改日期
    changeDate: function changeDate() {
      // console.log(this.value1)
      this.listQuery.localDate = this.value1;
      this.getData();
    },
    upSelect: function upSelect(val) {
      this.listQuery.tenantId = val.tenantId ? val.tenantId : undefined;
      this.listQuery.companyId = val.companyId ? val.companyId : undefined;
      this.getData();
    },
    getData: function getData() {
      var _this = this;
      statistics_index(this.listQuery).then(function (res) {
        console.log(res);
        _this.orderData.avgDuration.value = res.data.avgDuration;
        _this.orderData.avgMileage.value = res.data.avgMileage;
        _this.orderData.avgMoney.value = res.data.avgMoney;
        _this.orderData.avgOrder.value = res.data.avgOrder;
        _this.orderData.cancelOrderTotal.value = res.data.cancelOrderTotal;
        _this.orderData.completeOrderTotal.value = res.data.completeOrderTotal;
        _this.orderData.createOrderTotal.value = res.data.createOrderTotal;
        _this.orderData.orderCommission.value = res.data.orderCommission;
        _this.orderData.orderInsurance.value = res.data.orderInsurance;
        _this.orderData.payMoney.value = res.data.payMoney;
        _this.orderData.receivingOrderTotal.value = res.data.receivingOrderTotal;
        _this.driverData.completeDriver.value = res.data.completeDriver;
        _this.driverData.enterJobDriver.value = res.data.enterJobDriver;
        _this.driverData.onJobDriver.value = res.data.onJobDriver;
        _this.driverData.onlineDriver.value = res.data.onlineDriver;
        _this.driverData.onlineTimeGt60.value = res.data.onlineTimeGt60;
        _this.driverData.onlineTimeLte60.value = res.data.onlineTimeLte60;
        _this.driverData.quitJobDriver.value = res.data.quitJobDriver;
        _this.driverData.receivingDriver.value = res.data.receivingDriver;
        _this.driverData.refuseOrderTotal.value = res.data.refuseOrderTotal;
        _this.pieChartData = res.data.customerEChartsData;
        _this.ringChartData = res.data.driverOnlineHoursEchartsData;
        _this.lineChartData = {
          xData: [],
          yData: [],
          yData1: []
        };
        res.data.orderSourcesEChartsData.map(function (item) {
          _this.lineChartData.xData.push(item.name);
          _this.lineChartData.yData.push(item.value);
          _this.yData2 = _this.lineChartData.yData;
        });
        res.data.newCustomerOrderSourcesEChartsData.map(function (item) {
          _this.lineChartData.yData1.push(item.value);
          _this.yData3 = _this.lineChartData.yData1;
        });
        var yData2 = _this.yData2;
        var yData3 = _this.yData3;
        console.log(yData2);
        console.log(yData3);
        _this.yData4 = yData2.map(function (value, index) {
          return value - yData3[index];
        });
        _this.lineChartData.yData = _this.yData4;
      });
    }
  }
};