var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "viewport" }, [
      _c("div", { staticClass: "column" }, [
        _vm._m(0),
        _c("div", { staticClass: "monitor panel" }, [
          _c("div", { staticClass: "inner" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "content", staticStyle: { display: "block" } },
              [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "marquee-view" },
                  [
                    _c(
                      "vue-seamless-scroll",
                      {
                        attrs: {
                          data: _vm.listData,
                          "class-option": _vm.seamlessScrollOption,
                        },
                      },
                      [
                        _c(
                          "ul",
                          _vm._l(_vm.listData, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "row",
                                staticStyle: { padding: "10px", margin: "5px" },
                              },
                              [
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.startLocation.slice(0, 10) + "..."
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.endLocation.slice(0, 10) + "..."
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(_vm._s(item.time)),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
        _vm._m(3),
      ]),
      _vm._m(4),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "order panel" }, [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "filter" }, [
              _c(
                "a",
                {
                  class: _vm.nowOrderData == "day365" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setOrderData("day365")
                    },
                  },
                },
                [_vm._v("365天")]
              ),
              _c(
                "a",
                {
                  class: _vm.nowOrderData == "day90" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setOrderData("day90")
                    },
                  },
                },
                [_vm._v("90天")]
              ),
              _c(
                "a",
                {
                  class: _vm.nowOrderData == "day30" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setOrderData("day30")
                    },
                  },
                },
                [_vm._v("30天")]
              ),
              _c(
                "a",
                {
                  class: _vm.nowOrderData == "day1" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setOrderData("day1")
                    },
                  },
                },
                [_vm._v("24小时")]
              ),
            ]),
            _c("div", { staticClass: "data" }, [
              _c("div", { staticClass: "item" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.orderData[_vm.nowOrderData].orders)),
                ]),
                _vm._m(5),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.orderData[_vm.nowOrderData].amount)),
                ]),
                _vm._m(6),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "sales panel" }, [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "caption" }, [
              _c("h3", [_vm._v("订单统计")]),
              _c(
                "a",
                {
                  class: _vm.lineType == "year" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setLineData("year")
                    },
                  },
                },
                [_vm._v("年")]
              ),
              _c(
                "a",
                {
                  class: _vm.lineType == "quarter" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setLineData("quarter")
                    },
                  },
                },
                [_vm._v("季")]
              ),
              _c(
                "a",
                {
                  class: _vm.lineType == "month" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setLineData("month")
                    },
                  },
                },
                [_vm._v("月")]
              ),
              _c(
                "a",
                {
                  class: _vm.lineType == "week" ? "active" : "",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setLineData("week")
                    },
                  },
                },
                [_vm._v("周")]
              ),
            ]),
            _vm._m(7),
          ]),
        ]),
        _vm._m(8),
        _c("div", { staticClass: "top panel" }, [
          _c("div", { staticClass: "inner" }, [
            _c("div", { staticClass: "province" }, [
              _vm._m(9),
              _c("div", { staticClass: "data" }, [
                _c("ul", { staticClass: "sup" }, [
                  _c(
                    "li",
                    {
                      class: _vm.hotType == "beijing" ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setHotData("beijing")
                        },
                      },
                    },
                    [_c("span", [_vm._v("分公司一")]), _vm._m(10)]
                  ),
                  _c(
                    "li",
                    {
                      class: _vm.hotType == "hebei" ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setHotData("hebei")
                        },
                      },
                    },
                    [_c("span", [_vm._v("分公司二")]), _vm._m(11)]
                  ),
                  _c(
                    "li",
                    {
                      class: _vm.hotType == "shanghai" ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setHotData("shanghai")
                        },
                      },
                    },
                    [_c("span", [_vm._v("分公司三")]), _vm._m(12)]
                  ),
                  _c(
                    "li",
                    {
                      class: _vm.hotType == "jiangsu" ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setHotData("jiangsu")
                        },
                      },
                    },
                    [_c("span", [_vm._v("分公司四")]), _vm._m(13)]
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "sub" },
                  _vm._l(_vm.hotData[_vm.hotType], function (item, index) {
                    return _c("li", { key: index }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("span", [
                        _vm._v(" " + _vm._s(item.num)),
                        _c("s", { staticClass: "icon-up" }),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overview panel" }, [
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "item" }, [
          _c("h4", [_vm._v("2,190")]),
          _c("span", [
            _c("i", {
              staticClass: "icon-dot",
              staticStyle: { color: "#006cff" },
            }),
            _vm._v(" 忙碌司机数 "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("h4", [_vm._v("190")]),
          _c("span", [
            _c("i", {
              staticClass: "icon-dot",
              staticStyle: { color: "#6acca3" },
            }),
            _vm._v(" 闲置司机数 "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("h4", [_vm._v("3,001")]),
          _c("span", [
            _c("i", {
              staticClass: "icon-dot",
              staticStyle: { color: "#6acca3" },
            }),
            _vm._v(" 上线司机数 "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("h4", [_vm._v("108")]),
          _c("span", [
            _c("i", {
              staticClass: "icon-dot",
              staticStyle: { color: "#ed3f35" },
            }),
            _vm._v(" 总司机数 "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tabs" }, [
      _c(
        "a",
        {
          staticClass: "active",
          attrs: { href: "javascript:;", "data-index": "0" },
        },
        [_vm._v("实时订单列表")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "head", staticStyle: { "padding-right": "0" } },
      [
        _c("span", { staticClass: "col" }, [_vm._v("订单起始点")]),
        _c("span", { staticClass: "col" }, [_vm._v("订单结束点")]),
        _c(
          "span",
          {
            staticClass: "col",
            staticStyle: { "justify-content": "space-around" },
          },
          [_vm._v("开始时间")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "point panel" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h3", [_vm._v("各区订单分布统计")]),
        _c("div", { staticClass: "chart" }, [
          _c("div", { staticClass: "pie", attrs: { id: "pie" } }),
          _c("div", { staticClass: "data" }, [
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("320,11")]),
              _c("span", [
                _c("i", {
                  staticClass: "icon-dot",
                  staticStyle: { color: "#ed3f35" },
                }),
                _vm._v(" 总数 "),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("418")]),
              _c("span", [
                _c("i", {
                  staticClass: "icon-dot",
                  staticStyle: { color: "#eacf19" },
                }),
                _vm._v(" 本月新增 "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("div", { staticClass: "map" }, [
        _c("h3", [
          _c("span", { staticClass: "icon-cube" }),
          _vm._v(" 近一周订单热力图 "),
        ]),
        _c("div", { staticClass: "chart" }, [
          _c("div", { staticClass: "geo", attrs: { id: "container" } }),
        ]),
      ]),
      _c("div", { staticClass: "users panel" }, [
        _c("div", { staticClass: "inner" }, [
          _c("h3", [_vm._v("司机服务时长")]),
          _c("div", { staticClass: "chart" }, [
            _c("div", { staticClass: "bar", attrs: { id: "bar" } }),
            _c("div", { staticClass: "data" }, [
              _c("div", { staticClass: "item" }, [
                _c("h4", [_vm._v("120,899")]),
                _c("span", [
                  _c("i", {
                    staticClass: "icon-dot",
                    staticStyle: { color: "#ed3f35" },
                  }),
                  _vm._v(" 总量 "),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("h4", [_vm._v("248")]),
                _c("span", [
                  _c("i", {
                    staticClass: "icon-dot",
                    staticStyle: { color: "#eacf19" },
                  }),
                  _vm._v(" 本月新增 "),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "icon-dot", staticStyle: { color: "#ed3f35" } }),
      _vm._v(" 订单量（条） "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "icon-dot", staticStyle: { color: "#eacf19" } }),
      _vm._v(" 服务时长（分钟） "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart" }, [
      _c("div", { staticClass: "label" }, [_vm._v("单位:万")]),
      _c("div", { staticClass: "line", attrs: { id: "line" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "channel panel" }, [
        _c("div", { staticClass: "inner" }, [
          _c("h3", [_vm._v("关键数据")]),
          _c("div", { staticClass: "data" }, [
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("39")]),
              _c("span", [
                _c("i", { staticClass: "icon-plane" }),
                _vm._v(" 服务总次数 "),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("28")]),
              _c("span", [
                _c("i", { staticClass: "icon-bag" }),
                _vm._v(" 服务总人数 "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "data" }, [
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("20")]),
              _c("span", [
                _c("i", { staticClass: "icon-train" }),
                _vm._v(" 从业人数 "),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("h4", [_vm._v("13")]),
              _c("span", [
                _c("i", { staticClass: "icon-bus" }),
                _vm._v(" 服务总时长 "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "quarter panel" }, [
        _c("div", { staticClass: "inner" }, [
          _c("h3", [_vm._v("昼夜订单对比")]),
          _c("div", { staticClass: "chart" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "gauge", attrs: { id: "gauge" } }),
              _c("div", { staticClass: "label" }, [
                _vm._v("75"),
                _c("small", [_vm._v(" %")]),
              ]),
            ]),
            _c("div", { staticClass: "data" }, [
              _c("div", { staticClass: "item" }, [
                _c("h4", [_vm._v("1,321")]),
                _c("span", [
                  _c("i", {
                    staticClass: "icon-dot",
                    staticStyle: { color: "#6acca3" },
                  }),
                  _vm._v(" 白天订单量 "),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("h4", [_vm._v("1500")]),
                _c("span", [
                  _c("i", {
                    staticClass: "icon-dot",
                    staticStyle: { color: "#ed3f35" },
                  }),
                  _vm._v(" 黑夜订单量 "),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("服务次数 "),
      _c("i", { staticClass: "date" }, [_vm._v("// 近30日 //")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("25,179 "), _c("s", { staticClass: "icon-up" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("23,252 "),
      _c("s", { staticClass: "icon-down" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("20,760 "), _c("s", { staticClass: "icon-up" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("23,252 "),
      _c("s", { staticClass: "icon-down" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }