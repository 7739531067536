import request from '@/utils/request';
// 租户列表
export function page(data) {
  return request({
    url: 'manager/tenant/page',
    method: 'get',
    params: data
  });
}
// 新增租户
export function add(data) {
  return request({
    url: 'manager/tenant/add',
    method: 'post',
    data: data
  });
}
// 修改租户
export function update(data) {
  return request({
    url: 'manager/tenant/update',
    method: 'post',
    data: data
  });
}
// 租户详情
export function getTenant(data) {
  return request({
    url: 'manager/tenant/get',
    method: 'get',
    params: data
  });
}
// 获取租户下司机、灰度司机
export function getDriverTest(data) {
  return request({
    url: '/manager/app-version/get-driver-test',
    method: 'get',
    params: data
  });
}
// 添加灰度司机
export function addDriverTest(data) {
  return request({
    url: '/manager/app-version/add-driver-test',
    method: 'post',
    data: data
  });
}
// 修改密码
export function passwordTenant(data) {
  return request({
    url: '/manager/tenant/update/password',
    method: 'post',
    data: data
  });
}
// 租户查询下拉菜单
export function getTenantList(data) {
  return request({
    url: 'manager/tenant/drop-down-list',
    method: 'get',
    params: data
  });
}