import request from '@/utils/request';
export function get_order_image(data) {
  return request({
    url: 'manager/order/get-order-image',
    method: 'get',
    params: data
  });
}
// 新单
export function customer_order(data) {
  return request({
    url: 'manager/order/customer-order',
    method: 'get',
    params: data
  });
}
export function customer_unpaid_order(data) {
  return request({
    url: '/manager/order/customer-unpaid-order',
    method: 'get',
    params: data
  });
}

// 预估费用
export function estimated_fee(data) {
  return request({
    url: 'manager/order/estimated-fee',
    method: 'get',
    params: data
  });
}
// 计算代检车预估费用
export function check_car_estimated_fee(data) {
  return request({
    url: 'manager/order/check-car/estimated-fee',
    method: 'get',
    params: data
  });
}

// 获取音频
export function get_audio(data) {
  return request({
    url: 'manager/order/get-order-voice',
    method: 'get',
    params: data
  });
}

// 创建代检车订单

export function create_check_car(data) {
  return request({
    url: 'manager/order/create-check-car',
    method: 'post',
    data: data
  });
}
// 获取订单详情（代检车）

export function check_car_get(params) {
  return request({
    url: 'manager/order/check-car/get',
    method: 'get',
    params: params
  });
}