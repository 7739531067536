import request from '@/utils/request';
export function getRouters(data) {
  return request({
    url: 'manager/user-menu/routers',
    method: 'get',
    params: data
  });
}
// 获取用户选择的菜单
export function getSelect(data) {
  return request({
    url: 'manager/user-menu/get-select',
    method: 'get',
    params: data
  });
}
export function getUserSelect(data) {
  return request({
    url: 'manager/user-menu/get-user-select',
    method: 'get',
    params: data
  });
}