var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "所属租户", prop: "status" } },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择租户",
                clearable: _vm.clearTenant,
                size: "small",
                filterable: "",
              },
              on: { change: _vm.changeTenant },
              model: {
                value: _vm.queryParams.tenantId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "tenantId", $$v)
                },
                expression: "queryParams.tenantId",
              },
            },
            _vm._l(_vm.tenantList, function (dict) {
              return _c("el-option", {
                key: dict.id,
                attrs: { label: dict.name, value: dict.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "所属公司", prop: "companyId" } },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择公司",
                clearable: _vm.clearCompany,
                size: "small",
                filterable: "",
              },
              on: { change: _vm.changeCompany },
              model: {
                value: _vm.queryParams.companyId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "companyId", $$v)
                },
                expression: "queryParams.companyId",
              },
            },
            _vm._l(_vm.companyList, function (dict) {
              return _c("el-option", {
                key: dict.id,
                attrs: { label: dict.companyAbbreviation, value: dict.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }