import request from '@/utils/request';
// 获取公司树
export function getCompanyTree(data) {
  return request({
    url: 'manager/company/get-tree',
    method: 'get',
    params: data
  });
}
export function getCompanyList(data) {
  return request({
    url: 'manager/company/list',
    method: 'get',
    params: data
  });
}
export function company_get_drop_list(data) {
  return request({
    url: 'manager/company/get-drop-list',
    method: 'get',
    params: data
  });
}
export function addCompany(data) {
  return request({
    url: 'manager/company/create',
    method: 'post',
    data: data
  });
}
export function updateStatus(data) {
  return request({
    url: '/manager/company/update-status',
    method: 'post',
    data: data
  });
}
export function updateCompany(data) {
  return request({
    url: '/manager/company/update',
    method: 'post',
    data: data
  });
}
export function getEnabledCompanyList(data) {
  return request({
    url: 'manager/company/get-enable-drop-list',
    method: 'get',
    params: data
  });
}
export function getCompanyExcludeList(data) {
  return request({
    url: 'manager/company/get-exclude-list',
    method: 'get',
    params: data
  });
}